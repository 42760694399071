import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Hero from '../components/home/Hero';
import Why from '../components/home/Why';
import How from '../components/home/How';

type IndexProps = {
  data: IndexQueryData
};
export default function Index({ data }:IndexProps) {
  return <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content={data.site.siteMetadata.description} />
      <title>{data.site.siteMetadata.name}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link href="https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap" rel="stylesheet" />
    </Helmet>
    <Hero />
    <Why />
    <How />
  </>;
}

type IndexQueryData = {
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
};
export const query = graphql`
  query IndexQuery{
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;
