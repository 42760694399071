import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { media } from '../../includes/breakpoints';
import Section from '../layout/Section';

const StyledSection = styled(Section)`
  .why {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3% 20% 3% 20%;
  }

  .why-header {
    text-align: center;
    font-weight: bold;
    color: #7E8085;
  }

  .boxes {
    display: flex;
    gap: 3rem;
  }

  .point {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .box-text {
    max-width: 90%;
    font-size: 16px;
    line-height: 28px;
    color: #54565A;
  }

  .box-text span {
    color: #2F5BEA;
  }
`;

export default function Why(){
  return <StyledSection id="why">
    <div className="why">
      <div className="why-header">
        <p>WHY CHOOSE US?</p>
        <h2>A simple, proven way to boost your <br /> team performance</h2>
      </div>
      <div className="boxes">
        <div className="box">
          <div className="point">
            <div className="icon">
              <StaticImage src="../../images/family.png" alt="icon-1" />
            </div>
            <h3>One Platform</h3>
          </div>
          <div className="box-text"><span>No more Mulitple Platforms.</span> Now it had seen unable uneasy. 
        Drawings can followed by improved out sociable not. Earnestly so do instantly pretended.
          </div>
        </div>
        <div className="box">
          <div className="point">
            <div className="icon">
              <StaticImage src="../../images/b-meeting.png" alt="icon-2" />
            </div>
            <h3>Communication</h3>
          </div>
          <div className="box-text"><span>No more hassle in communication.</span> New had happen unable uneasy. 
        Drawings can followed improved out sociable not. Earnestly so do instantly pretended. 
          </div>
        </div>
        <div className="box">
          <div className="point">
            <div className="icon">
              <StaticImage src="../../images/filter-organization.png" alt="icon-3" />
            </div>
            <h3>Project Management</h3>
          </div>
          <div className="box-text"><span>Easily manage your projects.</span> Drawings can followed improved out sociable not. 
        Earnestly so do instantly pretended. See general few civilly amiable pleased account carried.
          </div>
        </div>
      </div>
    </div>
  </StyledSection>;
}
