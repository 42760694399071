import React from 'react';
import styled from 'styled-components';
import Section from '../layout/Section';
import { StaticImage } from 'gatsby-plugin-image';
import backgroundSVG from '../../images/waves.svg';

const StyledSection = styled(Section)`
.hero-wrapper {
  /*
  display: flex;
  justify-content: center;
  */
  padding-top: 5%;
  background-color: #2F5BEA;
}

.hero-svg {
  background-image: url(${backgroundSVG});
  background-position: center bottom -25%;
  background-repeat: no-repeat;
}

.hero {
  display: flex;
  margin: 0 20%;
  color: white;
  font-size: 22px;
}

.hero-cta {
  flex: 1 1 0;
}

.hero-cta p {
  color: #C5D0E6;
  line-height: 34px;
}

.hero-image {
  flex: 1 1 0;
}

.hero-img {
  max-height: 90%;
  max-width: 150%;
  height: auto;
  width: auto;
}

.cta-button {
  display: inline-block;
  color: #2F5BEA;
  font-weight: bold;
  font-size: 16px;
  background-color: white;
  padding: 1.5rem 2.5rem;
  border-radius: 5px;
}
`;

// Regarding H1 tags and SEO https://moz.com/blog/h1-seo-experiment
export default function Hero() {
  return (
    <StyledSection id="hero">
      <div className="hero-wrapper">
        <div className="hero-svg">
          <div className="hero">
            <div className="hero-cta">
              <h1>When you partner with us,<br /> you've got the best.</h1>
              <p>That headline is pretty weak isn't it? This sub-head<br /> isn't much better!</p>
              <a href="#lp-form">
                <div className="cta-button">
                  LEARN MORE
                </div>
              </a>
            </div>
            <div className="hero-image">
              <StaticImage 
                src="../../images/Group 198.png" 
                alt="Hero Image"
              />
            </div>
          </div>
        </div>
      </div>
    </StyledSection>
  );
}
