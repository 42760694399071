import React from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/pagination';
import Section from '../layout/Section';
import { StaticImage } from 'gatsby-plugin-image';

const StyledSection = styled(Section)`
  .section-wrapper {
    background-color: #F6F8F9;
  }

  .section1 {
    display: flex;
    padding: 5% 20%;
  }

  .form1 {
    flex: 1 1 0;
  }

  .form1 h2 {
    margin-top: 0;
  }

  .form-layout {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .form-div input {
    width: 75%;
    height: 3.5rem;
    border-radius: 5px;
    border: solid #979797;
    font-size: 18px;
  }

  .form-image {
    flex: 1 1 0;
  }

  .form-img {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
  }

  .form-button {
    width: fit-content;
    color: white;
    font-weight: bold;
    font-size: 16px;
    background-color: #2F5BEA;
    padding: 1.57rem 3.5rem;
    border: none;
    border-radius: 5px;
  }
`;

export default function How(){

  return <StyledSection id="how">
    <div className="section-wrapper">
      <div className="section1">
        <div className="form1" id="lp-form">
          <h2>Get in touch! </h2>
          <form className="form-layout" method="POST" action="">
            <div className="form-div"> <label htmlFor="name">Your Name (required)</label><input id="name" type="text" name="name" required /></div>
            <div className="form-div"> <label htmlFor="email">Your Email (required)</label><input id="email" type="email" name="email" required /></div>
            <div className="form-div"> <label htmlFor="company">Company Name</label><input id="company" type="text" name="company" /></div>
            <input className="form-button" type="submit" value="SUBMIT" />
          </form>
        </div>
        <div className="form-image">
          <StaticImage className="form-img" src="../../images/IMG.png" alt="Form Image" /> 
        </div>
      </div>
    </div>
  </StyledSection>;
}
